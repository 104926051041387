$(document).ready(function () {
  ("use strict");

  // -----------------
  // max-width: 799px
  // -----------------

  var mediaQuery = window.matchMedia("(max-width: 799px)");

  mediaQuery.addListener(max799);

  function max799(mediaQuery) {
    if (mediaQuery.matches) {
      // Mobile menu
      // -----------
      // crear el botó del menú
      // $('.main-nav').prepend('<button class="hamburger hamburger--3dx menu-button" aria-expanded="false" aria-label="Menú" id="nav"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="menu-button__label vh">Menú</span></button>')
      // ocultar el menú
      $(".menu-wrapper")
        .attr("hidden", "")
        .addClass("js-hidden");
    } else {
      // ≥ 800px

      // Mobile menu
      // -----------
      // treure el botó
      // $('.main-nav button').remove();
      // mostrar el menú
      $(".menu-wrapper")
        .removeAttr("hidden")
        .removeClass("js-hidden");
    }
  }

  // clicar el botó
  $(".menu-button").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    $("body").toggleClass("js-menu-open");
    // canviar atributs del botó i del menú
    if ($(this).attr("aria-expanded") == "true") {
      $(this).attr("aria-expanded", "false");
      $(".menu-wrapper")
        .attr("hidden", "")
        .addClass("js-hidden");
    } else {
      $(this).attr("aria-expanded", "true");
      $(".menu-wrapper")
        .removeAttr("hidden")
        .removeClass("js-hidden");
    }
  });

  max799(mediaQuery);

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // sticky nav
  // ----------

  var lastScrollTop = 0;
  $(window).scroll(function (event) {
    var st = $(this).scrollTop();
    if (st > lastScrollTop) {
      $("body:not(.kids) .main-header").addClass("sticky");
    } else {
      $("body:not(.kids) .main-header").removeClass("sticky");
    }
    lastScrollTop = st;
  });

  // ----------
  // scroll top
  // ----------

  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".top").fadeIn();
    } else {
      $(".top").fadeOut();
    }
  });

  // ------------
  // footer
  // ------------
  if ($(".main-content div:last-child").hasClass("section")) {
    $(".main-footer").addClass("collapsed");
  }

  // ------------
  // appendAround
  // ------------
  $(".tools-container").appendAround();

  // -----------
  // matchHeight
  // -----------
  $(".membre").matchHeight();

  // ---------------
  // llistat membres
  // ---------------
  $(".membre__foto").append("<span></span>");

  // ------------
  // Menú idiomes
  // ------------
  $(".lang-list").attr("hidden", "");
  $(".lang-button").on("click", function () {
    // canviar atributs del botó i del menú
    if ($(this).attr("aria-expanded") == "true") {
      $(this)
        .attr("aria-expanded", "false")
        .removeClass("open");
      $(".lang-list").attr("hidden", "");
    } else {
      $(this)
        .attr("aria-expanded", "true")
        .addClass("open");
      $(".lang-list").removeAttr("hidden");
    }
  });

  // --------------
  // Popup projecte
  // --------------
  $(".popup-fitxa").magnificPopup({
    type: "ajax",
    alignTop: true,
    overflowY: "scroll" // as we know that popup content is tall we set scroll overflow by default to avoid jump
  });

  $(".popup-modal").magnificPopup({
    type: "inline",
    alignTop: true,
    overflowY: "scroll" // as we know that popup content is tall we set scroll overflow by default to avoid jump
  });

  $('.galeriaImg').magnificPopup({
    delegate: 'a', // child items selector, by clicking on it popup will open
    type: 'image',
    gallery:{enabled:true}
    // other options
  });

  // ------
  // Slider
  // ------
  $(".galeria").slick({
    centerMode: true,
    variableWidth: true,
    // autoplay: true,
    // autoplaySpeed: 2000,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          variableWidth: false,
          centerMode: false,
          variableWidth: false
        }
      }
    ]
  });

  $('.carousel').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".tab-panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
                ? index + 1
                : e.which === 40
                  ? "down"
                  : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
                ? switchTab(e.currentTarget, tabs[dir])
                : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }

  // -----------
  // Collapsible  (https://heydon.github.io/inclusive-components-demos/collapsible-sections/progressive.html)
  // -----------
  (function () {
    // Get all the <h2> headings
    var headings = document.querySelectorAll(".collapsible h2");
    Array.prototype.forEach.call(headings, function (heading) {
      // Give each <h2> a toggle button child
      // with the SVG plus/minus icon
      // heading.innerHTML = '\n          <button aria-expanded="false">\n            '.concat(
      //   heading.textContent,
      //   '\n            <svg focusable="false" viewBox="0 0 24 16">\n              <path d="M24 0H0l12 16z"/>\n            </svg>\n          </button>\n        '
      // );
      heading.innerHTML = '<button aria-expanded="false">'.concat(
        heading.textContent
      );
      // Function to create a node list
      // of the content between this <h2> and the next

      var getContent = function getContent(elem) {
        var elems = [];

        while (
          elem.nextElementSibling &&
          elem.nextElementSibling.tagName !== "H2"
        ) {
          elems.push(elem.nextElementSibling);
          elem = elem.nextElementSibling;
        } // Delete the old versions of the content nodes

        elems.forEach(function (node) {
          node.parentNode.removeChild(node);
        });
        return elems;
      }; // Assign the contents to be expanded/collapsed (array)

      var contents = getContent(heading); // Create a wrapper element for `contents` and hide it

      var wrapper = document.createElement("div");
      wrapper.hidden = true; // Add each element of `contents` to `wrapper`

      contents.forEach(function (node) {
        wrapper.appendChild(node);
      }); // Add the wrapped content back into the DOM
      // after the heading

      heading.parentNode.insertBefore(wrapper, heading.nextElementSibling); // Assign the button

      var btn = heading.querySelector("button");

      btn.onclick = function () {
        // Cast the state as a boolean
        var expanded = btn.getAttribute("aria-expanded") === "true"; // Switch the state

        btn.setAttribute("aria-expanded", !expanded); // Switch the content's visibility

        wrapper.hidden = expanded;
      };
    });
  })();
});

// desplegables projecte recerca

$(".head-despl").click(function(){
  var contingut = $(this).next(".contingutDesplegable");
  contingut.toggle();
  $(this).toggleClass("open-despl");
});



// ----------
// Flatpickr
// ----------




var mes = $( ".mes" ).flatpickr({
  //minDate: "<?php echo date('Y-m-d',strtotime('first day of this month')) ?>",
  altInput: true,
  disableMobile: true,
    plugins: [
        new monthSelectPlugin({
            shorthand: true, //defaults to false
            dateFormat: "Y-m", //defaults to "F Y"
            altFormat: "M Y", //defaults to "F Y"
            //theme: "dark" // defaults to "light"
        })
    ],
  /*onValueUpdate: function (selectedDates, dateStr, instance) {
    rang.set('defaultDate', dateStr)
  }*/
});

var lang = $("html").attr("lang");
//if (lang == 'ca') lang = 'cat';

var rang = $( ".rang" ).flatpickr({
    "locale": lang,
    mode: "range",
    dateFormat: "d-m-Y",
    showMonths: 2,
    //defaultDate: "2020-11-26 14:30",
});

// ----------
// Datepicker
// ----------

$(function () {
  /*  $(".date").fdatepicker({
      format: "dd-mm-yyyy",
      language: "ca",
      weekStart: 1,
      leftArrow:
        '<button type="button"><img src="/media/img/bt-calendario-anterior.png" srcset="/media/img/bt-calendario-anterior@2x.png 2x" width="24" height="24" alt="Mes anterior" /></button>',
      rightArrow:
        '<button type="button"><img src="/media/img/bt-calendario-siguiente.png" srcset="/media/img/bt-calendario-siguiente@2x.png 2x" width="24" height="24" alt="Mes siguiente" /></button>'
    });*/



  var checkout = checkin = null;
  checkin = $('.date--1').fdatepicker({
    format: "dd-mm-yyyy",
    language: "ca",
    weekStart: 1,
    leftArrow:
      '<button type="button"><img src="/media/img/bt-calendario-anterior.png" srcset="/media/img/bt-calendario-anterior@2x.png 2x" width="24" height="24" alt="Mes anterior" /></button>',
    rightArrow:
      '<button type="button"><img src="/media/img/bt-calendario-siguiente.png" srcset="/media/img/bt-calendario-siguiente@2x.png 2x" width="24" height="24" alt="Mes siguiente" /></button>',
    onRender: function (date) {
      if ($('.date--2').val() == '' || checkout == null || typeof checkout.date == 'undefined') {
        return '';
      }
      console.log(checkout.date);
      return date.valueOf() > checkout.date.valueOf() ? 'disabled' : '';
    }
  }).data('datepicker');
  checkout = $('.date--2').fdatepicker({
    format: "dd-mm-yyyy",
    language: "ca",
    weekStart: 1,
    leftArrow:
      '<button type="button"><img src="/media/img/bt-calendario-anterior.png" srcset="/media/img/bt-calendario-anterior@2x.png 2x" width="24" height="24" alt="Mes anterior" /></button>',
    rightArrow:
      '<button type="button"><img src="/media/img/bt-calendario-siguiente.png" srcset="/media/img/bt-calendario-siguiente@2x.png 2x" width="24" height="24" alt="Mes siguiente" /></button>',

    onRender: function (date) {
      if ($('.date--1').val() == '' || checkin == null || typeof checkin.date == 'undefined') {
        return '';
      }
      return date.valueOf() <= checkin.date.valueOf() ? 'disabled' : '';
    }
  }).data('datepicker');
});

// --------
// Counters
// --------
$('.dada__num').each(function () {
  $(this).prop('Counter', 0).animate({
    Counter: $(this).text()
  }, {
    duration: 4000,
    easing: 'swing',
    step: function (now) {
      $(this).text(Math.ceil(now));
    }
  });
});

// ------------------------
// Detect objectFit support (https://codepen.io/pawelgrzybek/pen/Rrybqg)
// ------------------------
if ("objectFit" in document.documentElement.style === false) {
  // assign HTMLCollection with parents of images with objectFit to variable
  var container = document.getElementsByClassName("top-img-container");

  // Loop through HTMLCollection
  for (var i = 0; i < container.length; i++) {
    // Asign image source to variable
    var imageSource = container[i].querySelector("img").src;

    // Hide image
    container[i].querySelector("img").style.display = "none";

    // Add background-size: cover
    container[i].style.backgroundSize = "cover";

    // Add background-image: and put image source here
    container[i].style.backgroundImage = "url(" + imageSource + ")";

    // Add background-position: center center
    container[i].style.backgroundPosition = "center center";
  }
} else {
  // You don't have to worry
  // console.log("No worries, your browser supports objectFit");
}

// -------------------
// Informe Kids Corona
// -------------------
// $(".more").attr("hidden", "").insertBefore('<button type="button" aria-expanded="false" aria-controls="more" class="btn btn--big btn--disclosure">Read full summary</button>');
// $(".more").insertBefore('<button type="button" aria-expanded="false" aria-controls="more" class="btn btn--big btn--disclosure">Read full summary</button>');
// $(".btn--disclosure").on("click", function () {
//   // canviar atributs del botó i del menú
//   if ($(this).attr("aria-expanded") == "true") {
//     $(this)
//       .attr("aria-expanded", "false")
//       .removeClass("open");
//     $(".more").attr("hidden", "");
//   } else {
//     $(this)
//       .attr("aria-expanded", "true")
//       .addClass("open");
//     $(".more").removeAttr("hidden");
//   }
// });
/*
$(".report-container").after('<button type="button" class="btn btn--big btn--disclosure">Read full summary</button>');
$(".btn--disclosure").on("click", function () {
  $(".report-container").toggleClass("open");
  $(this).toggleClass("open");
});
*/
