// filters
$.expr[":"].external = function(obj) {
  "use strict";
  try {
    if (obj.href) {
      return !obj.href.match(/^mailto:/) && obj.hostname != location.hostname;
    }
    return false;
  } catch (e) {
    return false;
  }
};
$.expr[":"].download = function(obj) {
  "use strict";
  try {
    if (obj.href) {
      return (
        obj.href.match(
          /\.(pdf|doc|docx|xls||xlsx|cvx|jpg|gif|mp3|swf|txt|ppt|zip|gz|dmg)$/
        ) !== null
      );
    }
    return false;
  } catch (e) {
    return false;
  }
};
$.expr[":"].image = function(obj) {
  "use strict";
  try {
    if (obj.href) {
      return obj.href.match(/\.(jpg|png|jpeg|JPG|PNG)$/) !== null;
    }
    return false;
  } catch (e) {
    return false;
  }
};

$(document).ready(function() {
  "use strict";
  // enllaços externs en finestra nova
  $("a:external, a[rel=external]")
    .attr("target", "_blank")
    .addClass("external")
    .attr("rel", "external noreferrer");
  $("a:download, a[rel=document]")
    .attr("target", "_blank")
    .addClass("document")
    .attr("rel", "document");

  // enllaç al pare (per iframes, etc..)
  $("a[rel=parent]").attr("target", "_parent");

  // evitem spam als correus
  $("a[href*='(ELIMINAR)']").each(function() {
    var t = $(this);
    var omg = t.attr("href");
    var omg2 = t.text();
    t.attr("href", omg.replace("(ELIMINAR)", ""));
    t.text(omg2.replace("(ELIMINAR)", ""));
  });

  // protecció antispam
  $('input[name="houdiniControl"]').remove();

  // identifiquem el primer i l'últim element de les llistes
  $("ul, ol").each(function() {
    var t = $(this);
    t.children("li:first").addClass("first");
    t.children("li:last").addClass("last");
  });

  // popups
  $(".popup").magnificPopup({
    type: "image"
  });

  $(".gallery-item").magnificPopup({
    type: "image",
    gallery: {
      enabled: true
    }
  });

  // $('a[href*="youtube.com"]').attr('class', 'popup-youtube');

  $(".popup-youtube").magnificPopup({
    disableOn: 700,
    type: "iframe",
    mainClass: "mfp-fade",
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });



  if ($(".infinite-paginator-button-wrapper").length) {
    $(document).on("click", ".infinite-paginator-button-wrapper a", function(
      e
    ) {
      $(this)
        .parent()
        .remove();

      var listURL = $(this).attr("href");
      $.ajax({
        url: listURL,
        type: "GET",
        success: function(data, textStatus, jqXHR) {
          $(this)
            .parent()
            .remove();
        },
        error: function(jqXHR, textStatus, errorThrown) {}
      });
      e.preventDefault(); //STOP default action
    });
  }
  $(document).foundation();
});
